import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import MainCard from 'components/mainCard';
import MultiCard from 'components/multiCard';
import styles from './contentRow.module.scss';

export default function ContentRow({ title, members, location }) {
  const isMainPage = (location?.pathname && location.pathname === '/') || false;
  if (!members) return null;

  const Card = isMainPage ? MainCard : MultiCard;
  const cards = members.map((member, index) => (
    <Card content={member} key={index} />
  ));

  return (
    <div
      className={classNames({
        [styles.mainContainer]: isMainPage,
        [styles.multiContainer]: !isMainPage,
      })}
    >
      <h2 className={styles.mainTitle}>{title}</h2>
      <div
        className={classNames({
          [styles.rowsMain]: isMainPage,
          [styles.rowsMulti]: !isMainPage,
        })}
      >
        {cards}
      </div>
    </div>
  );
}

/*
 *   Export graphQL fragment
 *   Gatsby magically lets any GraphQL query access this fragment
 *   https://www.gatsbyjs.org/docs/graphql-concepts/#fragments
 */
export const contentRowFragment = graphql`
  fragment ContentRowFragment on Node {
    ... on ContentfulContentRow {
      title
      members {
        ... on ContentfulCategory {
          id
          title
          slug
          description
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
        ... on ContentfulExternalLink {
          id
          title
          url
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
      }
    }
  }
`;
