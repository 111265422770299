import get from 'lodash/get';
import React from 'react';
import { ContentCard } from '@newsela/angelou';
import { ClassNames } from '@emotion/core';

export default function MultiCard({ content }) {
  const thumbnailUrl =
    get(content.thumbnail, 'file.url') ||
    'https://images.ctfassets.net/qg7w37hnqnjf/2ijL1X9U8gHmYfmiyxIcf3/95d9b1e085d1bdb4607e0136dbc43088/blue-paper-illustration-min.png';
  const contentType =
    content.contentType || content.__typename.replace('Contentful', '');
  let url = content.url;
  let title = content.title;
  let footer = '';
  if (contentType === 'Category') {
    url = `/category/${content.slug}/`;
  } else if (contentType === 'Article') {
    url = `/article/${content.slug}/`;
  }
  const contentTypeDisplayName = contentType
    .match(/[A-Z][a-z]+|[0-9]+/g)
    .join(' ');
  const contentCardStyles = (css) => ({
    root: css({ zIndex: 0 }),
    visual: {
      root: css({
        '&&&': {
          animation: 'none',
          background: 'white',
        },
      }),
    },
    textual: {
      loadingWrapper: css({
        '&& *': {
          animation: 'none',
        },
      }),
    },
  });
  return (
    <ClassNames>
      {({ css }) => (
        <ContentCard
          href={url}
          contentType={contentTypeDisplayName}
          title={title}
          img={{
            alt: get(content.thumbnail, 'title', 'image-not-available'),
            src: thumbnailUrl,
          }}
          __css={{
            '&&&>*': {
              gridTemplateRows: 'minmax(250px, 3fr) 1fr auto',
            },
          }}
          classes={contentCardStyles(css)}
          footer={footer}
        />
      )}
    </ClassNames>
  );
}
