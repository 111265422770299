import React from 'react';
import get from 'lodash/get';
import SmartLink from 'components/smartLink';
import styles from './mainCard.module.scss';

const Card = ({ content }) => {
  const thumbnailUrl =
    get(content.thumbnail, 'file.url') ||
    'https://images.ctfassets.net/qg7w37hnqnjf/2ijL1X9U8gHmYfmiyxIcf3/95d9b1e085d1bdb4607e0136dbc43088/blue-paper-illustration-min.png';
  const contentType =
    content.contentType || content.__typename?.replace('Contentful', '');

  let url = content.url;
  if (contentType === 'Category') {
    url = `/category/${content.slug}/`;
  } else if (contentType === 'Article') {
    url = `/article/${content.slug}/`;
  }

  return (
    <SmartLink className={styles.card} to={url}>
      <img
        className={styles.cardImage}
        alt={get(content.thumbnail, 'title', 'image-not-available')}
        src={thumbnailUrl}
      />
      <h3 className={styles.cardTitle}>{content.title}</h3>
      <div className={styles.cardDescription}>{content.description}</div>
      <div className={styles.cardLink}>Learn more</div>
    </SmartLink>
  );
};

export default Card;
