import React from 'react';
import { graphql } from 'gatsby';
import { Index } from 'lunr';
import ContentRow from 'components/contentRow';
import SearchForm from 'components/searchForm';
import styles from './search.module.scss';

export default function SearchPage({ data, location }) {
  const params = new URLSearchParams(location.search.slice(1));
  const needle = params.get('needle') || '';

  const { store } = data.LunrIndex;
  const index = Index.load(data.LunrIndex.index);
  let results = [];
  try {
    results = index.search(needle).map(({ ref }) => {
      return {
        slug: ref,
        ...store[ref],
      };
    });
  } catch (error) {
    // Handle errors?
    console.log(error);
  }

  return (
    <>
      <SearchForm initialQuery={needle} />
      {results.length ? (
        <ContentRow members={results} />
      ) : (
        <h1 className={styles.title}>Nothing found.</h1>
      )}
    </>
  );
}

export const pageQuery = graphql`
  query {
    LunrIndex
  }
`;
